import React, { useState } from "react"
import { css } from "@emotion/core"
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';

const MyLabel = styled.label`
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
`

const LabelTitle = styled.span`
  position: absolute;
  top: 7px;
  left: 0;
  font-size: 1em;
  transform-origin: 0 0;
  transition: all .2s ease;
  color: ${props =>
    props.isFocused ? '#7b1fa2': '#9e9e9e'};
  transform: ${props =>
    (props.isFocused || props.name !== "") ? 'translateY(-26px) scale(.75)' : ''};
`

const LabelBorder = styled.span`
  position: absolute;
  bottom: -11px;
  left: 0;
  height: 2px;
  width: 100%;
  background #7b1fa2;
  transform-origin: 0 0;
  transition: all .15s ease;
  transform: ${props =>
    props.isFocused ? 'scaleX(1)' : 'scaleX(0)'};
`

const MyInput = styled.input`
  width: 60%;
  border: 0;
  font-family: sans-serif;
  padding: 12px 12px 5px 12px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #C8CCD4;
  background: none;
  border-radius: 0;
  color: black;
  transition: all .15s ease;
  & :hover {
    background: #f7f7f7;
    border-bottom: 1px solid black;
  }
  & :focus {
    background: none;
    outline: none;
    border-bottom: 0px;
  }
`


const NetlifyForm = () => {
  const [state, setState] = useState({
    name: "",
    email: ""
  })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleFocus = (e) => {
    const inputFieldName = `${e.target.name}-focus`
    console.log('onFocus', state)

    setState({ 
      ...state,
      [inputFieldName]: true
    })
  }

  const handleBlur = (e) => {
    const inputFieldName = `${e.target.name}-focus`
    console.log('onBlur', state)

    setState({ 
      ...state,
      [inputFieldName]: false
    })
  }

  return(
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      css={css`
        margin: 1vh auto;
      `}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <div>
        <MyLabel>
          <MyInput type="text" name="name"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <LabelTitle isFocused={state['name-focus']} name={state.name}>Your Name</LabelTitle>
          <LabelBorder isFocused={state['name-focus']}></LabelBorder>
        </MyLabel>
      </div>
      <div css={css`
        margin-top: 1vh;
      `}>
        <MyLabel>
          <MyInput type="email" name="email"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <LabelTitle isFocused={state['email-focus']} name={state.email}>Your Email</LabelTitle>
          <LabelBorder isFocused={state['email-focus']}></LabelBorder>
        </MyLabel>
      </div>
      <div css={css`
        margin: 1vh 0;
      `}>
        <TextField
          id="outlined-message"
          label="Message"
          multiline
          rows="3"
          rowsMax="4"
          onChange={handleChange}
          name="message"
          margin="normal"
          variant="outlined"
          className="MessageCathy"
        />
      </div>
      <Button variant="outlined" type="submit">
        Send
      </Button>
    </form>
  )
}

export default NetlifyForm