import React, { Component } from "react"
import SEO from "../components/seo"

class Rudder extends Component {
  componentDidMount() {
    // set up and use external package as needed
    
  }
  render(props) {
    return (
      <div>
        <SEO title="Rudder" />
        my rudder
      </div>
    )
  }
}

export default Rudder