import React, { useState } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import nextPageBtn from "images/next-page-button.png"
import contactPic from "images/contact-bg.png"
import loveIcon from "images/heart.png"
import { useTranslation } from "react-i18next"

import SEO from "../components/seo"
import NetlifyForm from "components/netlifyForm"
import Rudder from "components/rudder"

const linkStyle = css`
  color: #273C57;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  position:relative;
`

const Contact = () => {
  const { t, i18n } = useTranslation()
  
  return (
    <div css={css`
      background: #ae68bc;
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding-right: 40px;
      @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        padding: 80px 0 0 0;
      }
    `}>
      <div css={css`
          flex: 1 1 auto;
          background-color: #eaeced;
          padding: 3% 4% 3%;
          display: grid;
          overflow: auto;
          grid-template-columns: 50% 50%;
          grid-template-rows: 18% auto 19%;
          grid-row-gap: 20px;
          grid-template-areas: 
            "title title"
            "email-area contact-description"
            "prev-page prev-page";
          @media (max-height: 730px) {
            grid-row-gap: 0px;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3% 6% 7%;
          }
      `}>
        <SEO title="Contact" />
        <h1 css={css`
          grid-area: title;
          align-self: end;
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: calc(1em + 4vw);
            margin: 1.5rem auto;
          }
        `}>
          Contact Me
        </h1>
        <div css={css`
          grid-area: email-area;
          padding-left: 2vw;
          margin-bottom: 1.45rem;
          position: relative;
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
          }
        `}>
          <img 
            src={contactPic}
            css={css`
              @media screen and (min-width : 768px) {
                  width: 29vw;
                  max-width: 480px;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                width: 70vw;
              }
            `}
            alt="Contact Me"
          />
          <span css={css` 
            position: absolute;
            color: #273C57;
            font-family: "Rajdhani-Bold";
            font-size: 1.2rem;
            left: 50%;
            top: 18%;
            letter-spacing: 1px;
          `}>
            hello@cathy-huang.com
          </span>
        </div>
        <div css={css`
          grid-area: contact-description;
          padding-left: 0.5vw;
          padding-right: 2vw;
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;
          }
        `}>
          <p>
          {t('contact.summary').split('\n').map(function(item, key) {
              return (
                <span key={key}>
                  {item}
                  <br/>
                </span>
              )
            })}
          </p>
          <NetlifyForm />
          <Rudder/>
        </div>
  
        <div css={css`
            grid-area: prev-page;
            align-self: start;
            padding-left: 2vw;
            @media (min-width: 320px) and (max-width: 480px) {
              padding-left: 0;
            }
          `}>
            <AniLink swipe direction="down"
              to="/projects"
              css={linkStyle}
            >
              <img
                src={nextPageBtn}
                alt="Next Page"
                css={css`
                  filter: drop-shadow(-10px -10px 5px rgba(0, 0, 0, 0.2));
                  transform: rotate(180deg);
                `}
              />
              <span css={css` 
                position: absolute;
                width: 150px;
                margin-left: -18%;
                margin-top: 30%; 
              `}>03 {t('navbar.project')}</span>
            </AniLink>
          </div>
      </div>
      <div
        css={css`
          flex: 0 0 50px;
          background: #ae68bc;
          padding: 12px;
          text-align: center;
          color: white;
          font-size: 1.1em;
          text-transform: uppercase;
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: 1em;

          }
        `}
      >
        <footer>
          © {new Date().getFullYear()}, Coded with
          <img
            css={css`
              margin: 0 4px;
            `}
            src={loveIcon}
          alt="Love"/>
          By Cathy Huang
        </footer>
      </div>
    </div>
  )
}



export default Contact
